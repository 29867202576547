var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './TextField.scss';
import classNames from 'classnames';
import FieldLabel from './FieldLabel';
export function TextField(_a) {
    var label = _a.label, disabled = _a.disabled, error = _a.error, children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, _c = _a.inputClassName, inputClassName = _c === void 0 ? '' : _c, _d = _a.linkHref, linkHref = _d === void 0 ? '' : _d, _e = _a.linkText, linkText = _e === void 0 ? '' : _e, _f = _a.fieldTitle, fieldTitle = _f === void 0 ? '' : _f, props = __rest(_a, ["label", "disabled", "error", "children", "className", "inputClassName", "linkHref", "linkText", "fieldTitle"]);
    var hasError = !!error;
    var TextFieldContainerClassNames = classNames({
        TextField__container: true,
        'TextField__container--error': hasError,
        'TextField__container--disabled': disabled,
    });
    return (_jsxs("div", __assign({ className: "TextField ".concat(className) }, { children: [_jsx(FieldLabel, { label: label, id: props.id, linkHref: linkHref, linkText: linkText }, void 0), _jsxs("div", __assign({ className: TextFieldContainerClassNames }, { children: [_jsx("input", __assign({ className: "TextField__input ".concat(inputClassName), disabled: disabled }, props), void 0), fieldTitle ? (_jsx("span", __assign({ className: "TextField__input-title" }, { children: fieldTitle }), void 0)) : null, children] }), void 0), error ? _jsx("p", __assign({ className: "TextField__error-feedback" }, { children: error }), void 0) : null] }), void 0));
}
