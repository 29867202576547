import { Paragraph } from '@payourse/style-guide';
import { Error, Success } from 'assets/icons';
import './Alert.scss';

interface AlertProps {
  message: string;
  level: 'error' | 'success';
}

export function Alert({ message, level }: AlertProps) {
  const isError = level === 'error';
  const modifier = isError ? '--error' : '--success';

  return (
    <div className={`Alert Alert${modifier}`}>
      {isError ? <Error /> : <Success />}
      <Paragraph size="m" className={`Alert__text Alert__text${modifier}`}>
        {message}
      </Paragraph>
    </div>
  );
}
