import { useRef, useCallback, useReducer } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

type State<T> = {
  error: ApiError;
  requestState: RequestType;
  response: T;
};

export function useFetch<T>() {
  const abortRef = useRef<AbortController>();
  const [state, setState] = useReducer(
    (state: State<T>, payload: Partial<State<T>>) => ({
      ...state,
      ...payload,
    }),
    {
      error: {} as ApiError,
      requestState: 'idle',
      response: {} as T,
    }
  );

  const fetch = useCallback(
    async (
      { url, data, method = 'GET', headers, ...config }: AxiosRequestConfig,
      callback?: (data: T) => void,
      errorCallback?: () => void
    ) => {
      try {
        setState({ requestState: 'loading' });
        if (abortRef.current) {
          abortRef.current.abort(); // cancel pending requests
        }
        const { signal } = (abortRef.current = new AbortController());

        let options = {
          baseURL,
          url,
          method,
          signal,
          headers: {
            'Content-Type': 'application/json',
            ...headers,
          },
          ...(data && { data: JSON.stringify(data) }),
          ...config,
        };

        const response = await axios(options);
        setState({
          requestState: 'success',
          response: response.data.data as T,
        });
        callback?.(response.data.data);
      } catch (error: any) {
        if (error?.name !== 'CanceledError') {
          setState({ requestState: 'error', error });
          errorCallback?.();
        }
      }
    },
    []
  );

  const isIdle = state.requestState === 'idle';
  const isLoading = state.requestState === 'loading';
  const isError = state.requestState === 'error';
  const isSuccess = state.requestState === 'success';

  return {
    ...state,
    fetch,
    isIdle,
    isLoading,
    isError,
    isSuccess,
  };
}
