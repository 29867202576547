import { ChangeEvent } from 'react';
import { Paragraph, TextField } from '@payourse/style-guide';
import { CustomSelect, Group } from 'components/shared';
import { useExchangePairs } from 'providers';
import { ArrowDownUp } from 'assets';
import { formatNumber } from 'helpers';
import './CryptoFiat.scss';

export function CryptoFiat() {
  const {
    cryptoOptions,
    fiatOptions,
    pairValue,
    activePair,
    error,
    handleInputChange,
    handleCurrencyChange,
  } = useExchangePairs();

  return (
    <div className="CryptoFiat">
      <div className="CryptoFiat__sendFunds">
        <Paragraph size="l">You send</Paragraph>
        <Group justifyContent="space-between">
          <CustomInput
            label=""
            name="crypto"
            id="crypto"
            onChange={handleInputChange}
            value={pairValue.crypto}
            placeholder="0.02268"
            className="CustomizedInput__field CryptoFiat__sendCash"
            autoFocus
          />
          <div>
            <CustomSelect
              name="crypto"
              onChange={handleCurrencyChange}
              options={cryptoOptions}
              value={cryptoOptions.find(
                (opt) => opt.value === activePair.cryptocurrency
              )}
            />
          </div>
        </Group>
      </div>

      <Group justifyContent="center" className="CryptoFiat__arrowContainer">
        <ArrowDownUp />
      </Group>

      <div className="CryptoFiat__sendFunds">
        <Paragraph size="l">You recieve</Paragraph>
        <Group justifyContent="space-between">
          <CustomInput
            label=""
            name="fiat"
            id="fiat"
            onChange={handleInputChange}
            value={pairValue.fiat}
            placeholder="0.02268"
            error={error}
            className="CustomizedInput__field CryptoFiat__sendCash"
          />
          <div>
            <CustomSelect
              name="fiat"
              options={fiatOptions}
              onChange={handleCurrencyChange}
              value={fiatOptions.find(
                (opt) => opt.value === activePair.fiat_currency
              )}
            />
          </div>
        </Group>
      </div>
    </div>
  );
}

function CustomInput({ value, onChange, ...props }: any) {
  const valueToString = value as string;
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const val = value?.toString().replaceAll(',', '');

    if (isNaN(Number(val))) return;
    onChange?.(event);
  };

  return (
    <TextField
      value={
        Number(valueToString) < 1
          ? valueToString
          : formatNumber(Number(valueToString.replaceAll(',', '')))
      }
      onChange={handleChange}
      {...props}
    />
  );
}
