import { CSSProperties } from 'react';
import './SkeletonLoader.scss';

interface SkeletonLoaderProps {
  width?: number;
  height: number;
  borderRadius?: number;
  customStyle?: CSSProperties;
  className?: string;
}

export function SkeletonLoader({
  width,
  className = '',
  height = 10,
  borderRadius = 8,
  customStyle,
}: SkeletonLoaderProps) {
  return (
    <div
      className={`SkeletonLoader ${className}`}
      style={{
        width: `${width ? width + 'px' : '100%'}`,
        height: `${height}px`,
        borderRadius: `${borderRadius}px`,
        ...customStyle,
      }}
    />
  );
}
