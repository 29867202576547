import { useEffect } from 'react';
import { useFetch } from 'hooks';

export function useExchangeValue() {
  const {
    fetch,
    response: exchange,
    isError,
    isLoading,
    isIdle,
  } = useFetch<Exchange>();
  // get exchange url
  const exchangeUrl = window.location.host;

  useEffect(() => {
    fetch({
      url: `/exchange?exchange_url=${exchangeUrl}`,
    });
  }, [fetch, exchangeUrl]);

  return { exchange, isError, isLoading: isLoading || isIdle };
}
