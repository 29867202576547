import { ReactNode, ChangeEvent } from 'react';
import './CustomCheckbox.scss';

interface CustomCheckboxProps {
  checked?: boolean;
  icon?: ReactNode;
  name?: string;
  label?: string;
  id?: string;
  value?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export function CustomCheckbox({
  icon,
  id,
  name,
  label,
  onChange,
  value,
  className = '',
  checked = false,
}: CustomCheckboxProps) {
  return (
    <div
      className={`CustomCheckbox ${className} ${
        checked ? 'CustomCheckbox--checked' : ''
      }`}
    >
      <div className="CustomCheckbox__icon">{icon}</div>
      <label htmlFor={id}>{label}</label>

      <div className="CustomCheckbox__input">
        <input
          type="checkbox"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
