import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Paragraph } from '@payourse/style-guide';
import { useExchange } from 'providers';
import { Group } from '../Group';
import './BusinessLogo.scss';

const formatName = (name = '') => {
  return name
    .split(' ')
    .map((s) => s[0])
    .join('')
    .substring(0, 1)
    .toUpperCase();
};

export function BusinessLogo({ solid = false }: { solid?: boolean }) {
  const [logoIsValid, setLogoIsValid] = useState(true);
  const { business_name, logo } = useExchange();

  return (
    <Link to="/" style={{ textDecoration: 'none' }}>
      <Group
        alignItems="center"
        className={`BusinessLogo BusinessLogo--${solid ? 'solid' : 'light'}`}
      >
        {logo && logoIsValid ? (
          <img
            className="BusinessLogo__img"
            width={32}
            height={32}
            src={logo}
            alt={business_name}
            onError={() => setLogoIsValid(false)}
          />
        ) : (
          <Avatar
            color="white"
            size="medium"
            text={formatName(business_name)}
          />
        )}
        <Paragraph size="l" className="BusinessLogo__name">
          {business_name}
        </Paragraph>
      </Group>
    </Link>
  );
}
