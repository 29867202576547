var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './TextField.scss';
function FieldLabel(_a) {
    var label = _a.label, linkHref = _a.linkHref, linkText = _a.linkText, id = _a.id;
    return (_jsxs("div", __assign({ className: "Field__label" }, { children: [_jsx("label", __assign({ className: "Field__label--primary", htmlFor: id }, { children: label }), void 0), linkText ? (_jsx("a", __assign({ href: linkHref, target: "_blank", rel: "noreferrer", className: "Field__label--link" }, { children: linkText }), void 0)) : null] }), void 0));
}
export default FieldLabel;
