import { useState, useEffect, ChangeEvent } from 'react';
import { useExchange } from 'providers';
import { ExchangePair } from 'types/pairs';
import { toNumber } from 'helpers';
import { useFetch } from 'hooks';

export function useExchangePairsValue() {
  const { exchange_id } = useExchange();

  const { fetch, response: pairs } = useFetch<{
    exchange_id: string;
    exchange_pairs: ExchangePair[];
  }>();

  const [pairValue, setPairValue] = useState({
    fiat: '',
    crypto: '',
  });

  const [activePair, setActivePair] = useState({
    fiat: '',
    crypto: '',
  });

  useEffect(() => {
    fetch(
      {
        url: `/exchange-pairs?exchange_id=${exchange_id}`,
      },
      (pairs) => {
        const defaultPair = pairs.exchange_pairs[0];

        setActivePair({
          crypto: defaultPair.cryptocurrency,
          fiat: defaultPair.fiat_currency,
        });

        setPairValue({
          fiat: `${Number(defaultPair.minimum_amount).toFixed(2)}`,
          crypto: `${Number(
            defaultPair.minimum_amount / defaultPair.price
          ).toFixed(8)}`,
        });
      }
    );
  }, [fetch, exchange_id]);

  const getActivepair = ({ crypto, fiat } = activePair) =>
    pairs.exchange_pairs
      ? pairs.exchange_pairs.find(
          (pair) =>
            pair.cryptocurrency === crypto && pair.fiat_currency === fiat
        ) || ({} as ExchangePair)
      : ({} as ExchangePair);

  const handleCurrencyChange = (option: OptionType) => {
    const { name, value } = option;
    let newActivePair: ExchangePair;

    setActivePair((s) => {
      newActivePair = getActivepair({ ...s, [name!]: value });
      return { ...s, [name!]: value };
    });

    // update pair values when currency changes
    if (name === 'crypto') {
      setPairValue((s) => ({
        ...s,
        fiat: `${
          toNumber(s.crypto) === 0
            ? 0
            : Number(toNumber(s.crypto) * newActivePair.price).toFixed(2)
        }`,
      }));
    }

    if (name === 'fiat') {
      setPairValue((s) => ({
        ...s,
        crypto: `${
          toNumber(s.fiat) === 0
            ? 0
            : Number(toNumber(s.fiat) / newActivePair.price).toFixed(8)
        }`,
      }));
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const val = value === '' ? 0 : parseFloat(value.replaceAll(',', ''));

    if (name === 'crypto') {
      setPairValue({
        fiat: `${val === 0 ? 0 : Number(val * active.price).toFixed(2)}`,
        crypto: value,
      });
    } else if (name === 'fiat') {
      setPairValue({
        fiat: value,
        crypto: `${val === 0 ? 0 : Number(val / active.price).toFixed(8)}`,
      });
    }
  };

  // derived values
  const active = getActivepair();

  const cryptoOptions = pairs.exchange_pairs
    ? pairs.exchange_pairs.map((pair) => ({
        name: 'crypto',
        label: pair.cryptocurrency,
        value: pair.cryptocurrency,
        icon: pair.cryptocurrency,
      }))
    : [];

  const fiatOptions = pairs.exchange_pairs
    ? pairs.exchange_pairs
        .filter((pair) => pair.cryptocurrency === activePair.crypto)
        .map((pair) => ({
          name: 'fiat',
          label: pair.fiat_currency,
          value: pair.fiat_currency,
          icon: pair.fiat_currency,
        }))
    : [];

  const error =
    toNumber(pairValue.fiat) < active.minimum_amount
      ? `The minimum you can send is ${active.fiat_currency} ${active.minimum_amount} `
      : toNumber(pairValue.fiat) > active.maximum_amount
      ? `The maximum you can send is ${active.fiat_currency} ${active.maximum_amount} `
      : '';

  return {
    handleInputChange,
    handleCurrencyChange,
    cryptoOptions,
    fiatOptions,
    pairValue,
    error,
    activePair: active,
  };
}
