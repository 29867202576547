import Select, { Props, StylesConfig } from 'react-select';
import { AssetLogo } from 'components/shared';
import { colors } from 'utils';
import './CustomSelect.scss';

const customSelectStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    borderLeft: state.isSelected ? `2px solid ${colors.success_green}` : '',
    backgroundColor: state.isSelected ? colors.accent_green : colors.white,
    color: colors.grey_2,
    padding: 10,
    '&:hover': {
      backgroundColor: colors.accent_green,
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    svg: {
      display: 'none',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: colors.neutral_6,
  }),
};

const OptionLabel = (option: OptionType) => {
  let { icon, label, name } = option;

  return (
    <div className="CustomSelect__option-label" key={name}>
      {icon ? (
        <div style={{ marginRight: 8 }}>
          <AssetLogo asset={icon} />
        </div>
      ) : null}
      <span>{label}</span>
    </div>
  );
};

interface CustomSelectProps extends Omit<Props, 'onChange'> {
  onChange?: (option: OptionType) => void;
  label?: string;
  options: OptionType[];
  isEnabled?: boolean;
}

export function CustomSelect({
  placeholder,
  options,
  onChange,
  isDisabled,
  label,
  className = '',
  ...props
}: CustomSelectProps) {
  return (
    <div className="CustomSelect">
      <label className="CustomSelect__label">{label}</label>
      <Select
        placeholder={placeholder}
        options={options}
        classNamePrefix="CustomSelect"
        className={`CustomSelect__select ${className}`}
        styles={customSelectStyles}
        onChange={onChange as any}
        getOptionLabel={OptionLabel as any}
        isDisabled={isDisabled}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: colors.grey_5,
          },
        })}
        {...props}
      />
    </div>
  );
}

export default CustomSelect;
