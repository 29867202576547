import './ProgressBar.scss';

interface ProgressBarProps {
  progress: number;
}

export function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <div className="ProgressBar">
      <div
        className="ProgressBar__indicator"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
}
