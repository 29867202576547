import { ReactNode } from 'react';
import { ErrorPage, Spinner, UnavailablePage } from 'components/shared';
import { createContext } from 'helpers';
import { useExchangeValue } from 'hooks';

const [useExchange, Provider] = createContext<Exchange>('ExchangeContext');

export function ExchangeProvider({ children }: { children: ReactNode }) {
  const { exchange, isError, isLoading } = useExchangeValue();

  if (isLoading) return <Spinner />;

  return (
    <Provider value={exchange}>
      {isError ? (
        <ErrorPage />
      ) : !exchange.setup_complete ? (
        <UnavailablePage />
      ) : (
        children
      )}
    </Provider>
  );
}

export { useExchange };
