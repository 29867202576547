import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import './Tabs.scss';

interface TabsProps {
  children: ReactElement[];
  className?: string;
}

export function Tabs({ children, className = '' }: TabsProps) {
  const [activeIndex, setActiveChild] = useState(0);
  const [recs, setRecs] = useState<DOMRect>();
  const activeRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    setRecs(activeRef.current?.getBoundingClientRect());
  }, [activeIndex]);

  return (
    <div className={`Tabs ${className}`}>
      <ul className="Tabs__nav">
        {children.map(({ props: { title } }, index) => {
          const props = activeIndex === index ? { ref: activeRef } : {};

          return (
            <li
              key={title}
              role="tab"
              tabIndex={0}
              onClick={() => setActiveChild(index)}
              onKeyDown={(event) => {
                if (event.code === 'Enter') {
                  setActiveChild(index);
                }
              }}
              className={`Tabs__title ${
                index === activeIndex ? 'Tabs__title--active' : ''
              } `}
              {...props}
            >
              {title}
            </li>
          );
        })}
        <div
          className="Tabs__indicator"
          style={{
            width: recs?.width,
            left: activeRef.current?.offsetLeft
          }}
        />
      </ul>

      <div className="Tabs__content">{children[activeIndex]}</div>
    </div>
  );
}

interface TabPaneProps {
  title: string;
  children: ReactNode;
  className?: string;
}

export function TabPane({ children, className }: TabPaneProps) {
  return <div className={className}>{children}</div>;
}
