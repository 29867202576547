import { ReactNode } from 'react';
import { createContext } from 'helpers';
import { useExchangePairsValue } from 'hooks';
import { ExchangePairContextType } from 'types/pairs';

const [useExchangePairs, Provider] = createContext<ExchangePairContextType>(
  'ExchangePairsContext'
);

export function ExchangePairsProvider({ children }: { children: ReactNode }) {
  const value = useExchangePairsValue();

  return <Provider value={value}>{children}</Provider>;
}

export { useExchangePairs };
