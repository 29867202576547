import { ReactNode } from 'react';
import { useThemeValue } from 'hooks';
import { createContext } from 'helpers';

const [useTheme, Provider] = createContext<ThemeColor>('ThemeContext');

export function ThemeProvider({ children }: { children: ReactNode }) {
  const theme = useThemeValue();

  return (
    <Provider value={theme}>
      <div className={`theme-${theme}`}>{children}</div>
    </Provider>
  );
}

export { useTheme };
