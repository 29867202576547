var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './Avatar.scss';
function Avatar(_a) {
    var color = _a.color, text = _a.text, _b = _a.size, size = _b === void 0 ? 'small' : _b, _c = _a.fill, fill = _c === void 0 ? 'solid' : _c;
    return (_jsx("div", __assign({ className: "Avatar Avatar--".concat(size, " background--").concat(fill, "-").concat(color) }, { children: text }), void 0));
}
export default Avatar;
