import { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import { Button } from '@payourse/style-guide';
import { Link } from 'react-router-dom';
import { BusinessLogo } from 'components/shared';
import { Menu } from 'assets';
import './NavBar.scss';

export const NavBar = () => {
  const [show, setShow] = useState(false);

  const hideNav = () => {
    if (show) {
      setShow(false);
    }
  };

  return (
    <div className="NavBar">
      <div className="NavBar__mobile">
        <div className="NavBar__mobile-logo">
          <BusinessLogo />
        </div>
        <Button variant="text" onClick={() => setShow(!show)}>
          <Menu />
        </Button>
      </div>
      <nav className={`NavBar__main NavBar__main-${show ? 'open' : ''}`}>
        <Button variant="text" className="NavBar__close" onClick={hideNav}>
          <Icon icon="cross" color="gray" size={18} />
        </Button>

        {/* As shown on the sidebar */}
        <div className="NavBar__logo">
          <BusinessLogo solid />
        </div>
        <ul className="NavBar__menuItem">
          <li>
            <Link
              onClick={hideNav}
              to={{
                pathname: '/',
                hash: 'howitworks',
              }}
            >
              How it works
            </Link>
          </li>
          <li>
            <Link onClick={hideNav} to="/#faqs">
              FAQ
            </Link>
          </li>
          <li>
            <Link onClick={hideNav} to="/#faqs">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
