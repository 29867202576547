import { Fragment } from 'react';
import { BTC, ETH, NGN, USDT } from 'assets/currencies';

export function AssetLogo({ asset }: { asset: string }) {
  switch (asset) {
    case 'BTC':
      return <BTC />;

    case 'USDT':
      return <USDT />;

    case 'NGN':
      return <NGN />;

    case 'ETH':
      return <ETH />;

    default:
      return <Fragment>{asset}</Fragment>;
  }
}
