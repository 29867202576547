var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './TextField.scss';
import classNames from 'classnames';
import FieldLabel from './FieldLabel';
export function SelectField(_a) {
    var _b;
    var label = _a.label, disabled = _a.disabled, error = _a.error, options = _a.options, value = _a.value, placeholder = _a.placeholder, _c = _a.className, className = _c === void 0 ? '' : _c, _d = _a.linkHref, linkHref = _d === void 0 ? '' : _d, _e = _a.linkText, linkText = _e === void 0 ? '' : _e, props = __rest(_a, ["label", "disabled", "error", "options", "value", "placeholder", "className", "linkHref", "linkText"]);
    var hasError = !!error;
    var selectClassNames = classNames((_b = {
            SelectField__input: true,
            'SelectField__input--error': hasError
        },
        _b[className] = !!className,
        _b));
    return (_jsxs("div", __assign({ className: "SelectField" }, { children: [_jsx(FieldLabel, { label: label, id: props.id, linkHref: linkHref, linkText: linkText }, void 0), _jsxs("select", __assign({ className: selectClassNames, value: value, disabled: disabled }, props, { children: [_jsx("option", __assign({ value: "" }, { children: placeholder || 'Please select an option' }), void 0), options.map(function (option) { return (_jsx("option", __assign({ value: option.value }, { children: option.label }), option.value)); })] }), void 0), hasError ? _jsx("p", __assign({ className: "TextField__error-feedback" }, { children: error }), void 0) : null] }), void 0));
}
