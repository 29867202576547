import { BusinessLogo, NavBar } from 'components/shared';
import './Header.scss';

export function Header() {
  return (
    <header className="Header">
      <NavBar />
      <div className="Header--desktop">
        <BusinessLogo solid />
      </div>
    </header>
  );
}
