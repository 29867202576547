import { Text, Heading } from '@payourse/style-guide';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useExchange } from 'providers';
import { BusinessLogo } from 'components/shared';
import './ErrorPage.scss';

export function ErrorPage() {
  const { business_name, brand_color } = useExchange();

  return (
    <div className={`ErrorPage theme-${brand_color}`}>
      <div className="ErrorPage__container">
        {business_name && <BusinessLogo />}

        <div className="ErrorPage__content">
          <ReactSVG src="/404-error.svg" />

          <Text
            weight="bold"
            text="Oops, we can’t seem to find the page you’re looking for. Let’s find a better place for you to go to."
            size="l"
          />

          {business_name && (
            <Link to="/" className="ErrorPage__cta">
              Go to Home Page
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export function UnavailablePage() {
  const { business_name, brand_color } = useExchange();

  return (
    <div className={`ErrorPage theme-${brand_color}`}>
      <div className="ErrorPage__container">
        <div className="ErrorPage__content">
          <ReactSVG src="/rocket.svg" />

          <Heading
            element="h1"
            size="xl"
            className="ErrorPage__title"
            text={`${business_name} is under construction.`}
          />
          <Text
            weight="normal"
            text="Exchange will go live once they activate it."
            size="l"
          />
        </div>
      </div>
    </div>
  );
}
