import { Button } from '@payourse/style-guide';
import { Link, LinkProps } from 'react-router-dom';
import './ThemedButton.scss';

export const ThemedButton = ({ ...props }) => {
  return (
    <Button
      {...props}
      className={`button-global ThemedButton ${props.className}`}
    />
  );
};

export const ThemedLink = ({ className, ...props }: LinkProps) => {
  return <Link className={`ThemedLink ${className}`} {...props} />;
};
