import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  ExchangePairsProvider,
  ExchangeProvider,
  ThemeProvider,
} from 'providers';
import { ErrorPage, Spinner } from 'components/shared';

const LandingPage = lazy(() => import('components/LandingPage'));
const SendFlow = lazy(() => import('components/SendFlow'));
const TransactionStatus = lazy(() => import('components/TransactionStatus'));

const App = () => {
  return (
    <Router>
      <ExchangeProvider>
        <ThemeProvider>
          <ExchangePairsProvider>
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/send" element={<SendFlow />} />
                <Route path="/status" element={<TransactionStatus />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Suspense>
          </ExchangePairsProvider>
        </ThemeProvider>
      </ExchangeProvider>
    </Router>
  );
};

export default App;
