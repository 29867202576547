import { createContext, useContext } from 'react';

export function createCtx<ContextType>(name: string) {
  const Context = createContext<ContextType | undefined>(undefined);
  Context.displayName = name;

  const useCtx = () => {
    const value = useContext(Context);

    if (!value) {
      throw new Error(
        `This context consumer must be a child of the ${name} Provider`
      );
    }

    return value;
  };

  return [useCtx, Context.Provider] as const;
}
