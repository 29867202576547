import { useExchange } from 'providers';

const colorMap: Record<string, string> = {
  '#635BFF': 'blue',
  '#47B881': 'green',
  '#6E62B6': 'purple',
  '#10899E': 'teal',
  '#BE449B': 'pink',
  '#996A13': 'yellow',
  '#DE7548': 'orange',
};

export function useThemeValue() {
  const { brand_color } = useExchange();
  const themeColor = colorMap[brand_color] || 'green';
  return themeColor as ThemeColor;
}
