import React from 'react';

interface GroupProps {
  children: React.ReactNode;
  className?: string;
  direction?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  alignItems?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
}

export function Group({
  children,
  className,
  direction = 'row',
  justifyContent = 'flex-start',
  alignItems = 'center',
}: GroupProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction,
        justifyContent,
        alignItems,
      }}
      className={className}
    >
      {children}
    </div>
  );
}
